<template>
  <div v-if="marketingData !== undefined">
    <vx-card ref="filterCard" class="user-list-filters mb-8 mt-6">
      <div class="vx-row">
        <div id="chatbotFilter" class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Chatbot</label>
          <v-select
            v-model="chatOption"
            :options="chatOptions"
            :clearable="false"
            class="mb-4 md:mb-0"
          />
        </div>
        <div id="dateFilter" class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Data</label>
          <vs-select
            icon="icon-calendar"
            icon-pack="feather"
            class="no-icon-border cursor-pointer w-full"
            placeholder="Filtre por data"
            size="small"
            v-model="filterParam"
          >
            <vs-select-item
              v-for="(item, index) in timeOptions"
              :key="index"
              :value="item"
              :text="item"
              class="w-full"
            />
            <div @click="popupDatePicker = true">
              <vs-select-item
                :value="timeOptionCustomValue"
                :text="timeOptionCustomText"
                class="w-full"
              />
            </div>
          </vs-select>
        </div>
        <!-- date-picker -->
        <vs-popup
          class="holamundo"
          :title="timeOptionCustomText"
          :active.sync="popupDatePicker"
        >
          <!-- popup body -->
          <date-picker
            ref="customDatePicker"
            :key="customDatePickerKey"
            v-model="datePickerValue"
            :default-value="datePickerValueDefault"
            :disabled-date="disabledDate"
            format="DD-MM-YYYY"
            range
            inline
            confirm
            @change="handleConfirmDatePicker"
          />
        </vs-popup>
        <!-- date-picker -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full hidden">
          <label class="text-sm opacity-75">Custom Data</label>
          <date-picker
            v-model="datePickerValue"
            :default-value="datePickerValueDefault"
            :disabled-date="disabledDate"
            format="DD-MM-YYYY"
            range
          >
            <template slot="icon-calendar">
              <i
                class="vs-icon notranslate icon-scale icon-select vs-select--icon feather icon-calendar null"
                style="
                  font-size: 14.875px;
                  font-weight: 100;
                  color: rgb(98, 98, 98);
                "
              ></i>
            </template>
          </date-picker>
        </div>
        <div class="right-0 absolute hidden md:block">
          <vs-tooltip
            :text="
              hasDownloadBlocked || hasDownloadBlockedLocal
                ? downloadBlockedMessage
                : $t('DownloadXLSX')
            "
          >
            <vs-button
              icon-pack="feather"
              icon="icon-file"
              size="medium"
              class="ml-4 mr-6 mt-6"
              @click="downloadCSV()"
              :disabled="hasDownloadBlocked || hasDownloadBlockedLocal"
              >{{ $t("DownloadXLSX") }}</vs-button
            >
          </vs-tooltip>
        </div>
      </div>
      <div class="vx-row">
        <div id="chatbotFilter" class="vx-col mt-3 w-full">
          <p class="text-sm opacity-75">{{ $t("InfoDash") }}</p>
        </div>
      </div>
    </vx-card>
    <div class="mt-8">
      <h3 class="m-2">Webchat</h3>
      <WebChatAnalytics
        v-if="kpiDataReady"
        :kpiData="kpiData"
        :key="`marketingKey-${marketingKey}`"
      />
    </div>

    <div>
      <h3>Inbox</h3>
      <div class="mt-8 vx-row">
        <ChartNewConversations
          :title="'Atendimentos Novos'"
          :newConversations="totalConversations"
          :chartData="marketingData.openedConversations"
          :key="`marketingKey1-${marketingKey}`"
        />
        <ConversationByChannel
          :title="$t('AttendanceByChannel')"
          :total="totalConversations"
          :marketingData="marketingData.perChannel"
          :key="`marketingKey2-${marketingKey}`"
        />
        <ConversationsByIntentions
          :cardTitle="'Atendimentos por Intenção'"
          :total="totalConversations"
          :convPerIntention="marketingData.perIntention"
          :key="`marketingKey3-${marketingKey}`"
        />
        <ConversationsByType
          :total="totalConversations"
          :cardTitle="'Atendimentos por Tipo'"
          :convPerType="marketingData.perType"
          :key="`marketingKey4-${marketingKey}`"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <ChartTMR
          title="Tempo médio de 1ª resposta"
          :chartData="TMRData"
          :average="TMRAverage"
          :key="`TMRKey-${keyTMR}`"
        />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <ChartTMA
          title="Tempo médio de atendimento"
          :chartData="TMAData"
          :average="TMAAverage"
          :key="`TMAKey-${keyTMA}`"
        />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <ChartNPS title="NPS" :chartData="NPSData" :key="`NPSKey-${keyNPS}`" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <ChartCSAT
          title="CSAT"
          :chartData="CSATData"
          :key="`CSATKey-${keyCSAT}`"
        />
      </div>
    </div>

    <div>
      <h3 class="m-2">Contatos</h3>
      <div class="vx-row">
        <ChartNewContacts
          :dateRangeData="getDateRange"
          :title="'Contatos Novos'"
          :newContacts="totalContacts"
          :chartData="contactData.newContacts"
          :key="`contactKey-${contactKey}`"
        />
        <ConversationByChannel
          :title="$t('ConversationByChannel')"
          color="#FFAB00"
          :total="totalContacts"
          :marketingData="contactData.perChannel"
          :key="`contactKey2-${contactKey}`"
        />
        <ConversationsByIntentions
          color="#FFAB00"
          :cardTitle="'Contatos por intenção'"
          :total="totalContacts"
          :convPerIntention="contactData.perIntention"
          :key="`contactKey3-${contactKey}`"
        />
        <ConversationsByType
          color="#FFAB00"
          :total="totalContacts"
          :cardTitle="'Contatos por Tipo'"
          :convPerType="contactData.perType"
          :key="`contactKey4-${contactKey}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ChartCSAT from "./components/ChartCSAT.vue";
import ChartNPS from "./components/ChartNPS.vue";
import ChartNewContacts from "./components/ChartNewContacts.vue";
import ChartNewConversations from "./components/ChartNewConversations.vue";
import ChartTMA from "./components/ChartTMA.vue";
import ChartTMR from "./components/ChartTMR.vue";
import ConversationByChannel from "./components/ConversationByChannel.vue";
import ConversationsByType from "./components/ConversationByType.vue";
import ConversationsByIntentions from "./components/ConversationsByIntentions.vue";
import WebChatAnalytics from "./components/WebChatAnalytics.vue";

//date picker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  mounted() {
    this.getChats();
    this.applyFilters(this.filterParam);
  },
  components: {
    vSelect,
    WebChatAnalytics,
    ChartNewConversations,
    ConversationsByIntentions,
    ConversationsByType,
    ConversationByChannel,
    ChartNewContacts,
    ChartTMR,
    ChartTMA,
    ChartNPS,
    ChartCSAT,
    DatePicker,
  },
  data() {
    return {
      TMRData: [],
      TMRAverage: {},
      keyTMR: 0,
      TMAData: [],
      TMAAverage: {},
      keyTMA: 0,
      NPSData: [],
      keyNPS: 0,
      CSATData: [],
      keyCSAT: 0,
      customDatePickerKey: 0,
      popupDatePicker: false,
      datePickerValueDefault: [
        new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), // 90 days ago
        new Date(), // today's date
      ],
      datePickerValue: null,
      timeOptionCustomValue: "Escolher Data",
      timeOptionCustomText: "Escolher Data",
      chatOption: [],
      chatOptions: [{ label: this.$t("AllChatbots"), value: "all" }],
      marketingKey: 0,
      contactKey: 0,
      marketingData: Object,
      contactData: Object,
      kpiData: null,
      kpiDataReady: false,
      newContactsConfig: Object,
      homeData: Object,
      chatContactsKey: 0,
      totalConversations: 0,
      totalContacts: 0,
      filterParam: "Este mês",
      timeOptions: [
        "Este mês",
        "Mês passado",
        "Esta semana",
        "Semana passada",
        "Este ano",
        "Ano passado",
      ],
      dataFilter: "thisMonth",
      downloadBlockedMessage: this.$t(
        "InboxConversationsXlsxGenerationBlocked1"
      ),
      hasDownloadBlockedLocal: false,
    };
  },
  watch: {
    filterParam: function () {
      if (this.filterParam === this.timeOptionCustomText) {
        // window.alert('custom data selected')
        this.popupDatePicker = true;
        return;
      }
      this.applyFilters(this.filterParam);
    },
    chatOption: function () {
      this.applyFilters(this.filterParam);
    },
  },
  computed: {
    getDateByPeriod() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let rangeDate;
      switch (this.filterParam) {
        case "Este mês":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: today,
          };
          break;
        case "Esta semana": {
          const firstDayOfWeek = today.getDate() - today.getDay();
          rangeDate = {
            start: new Date(
              today.getFullYear(),
              today.getMonth(),
              firstDayOfWeek
            ),
            end: today,
          };
          break;
        }
        case "Este ano":
          rangeDate = {
            start: new Date(today.getFullYear(), 0, 1),
            end: today,
          };
          break;
        case "Semana passada": {
          const lastWeekStart = new Date(today);
          lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
          const lastWeekEnd = new Date(lastWeekStart);
          lastWeekEnd.setDate(lastWeekStart.getDate() + 7);
          rangeDate = {
            start: lastWeekStart,
            end: lastWeekEnd,
          };
          break;
        }
        case "Mês passado":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            end: new Date(today.getFullYear(), today.getMonth(), 1, 0),
          };
          break;
        case "Ano passado":
          rangeDate = {
            start: new Date(today.getFullYear() - 1, 0, 1),
            end: new Date(today.getFullYear(), 0, 1),
          };
          break;
        case "Escolher Data":
          rangeDate = {
            start: new Date(this.datePickerValue[0]),
            end: new Date(this.datePickerValue[1]),
          };
          if (this.datePickerValue[0].toString() === this.datePickerValue[1].toString()) {
            rangeDate.start.setUTCHours(0, 0, 0, 0);
            rangeDate.end.setUTCHours(23, 59, 59, 59);
          }
          break;
        default:
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: today,
          };
      }

      return rangeDate;
    },
    getDateRange() {
      let dateMatch;
      if (this.dataFilter === "thisMonth") {
        const thisMonth = new Date();
        thisMonth.setHours(0, 0, 0, 0);

        const firstDay = new Date(
          thisMonth.getFullYear(),
          thisMonth.getMonth(),
          1
        );

        const lastDay = new Date(thisMonth);

        lastDay.setDate(lastDay.getDate() - 1);

        dateMatch = { start: firstDay, end: lastDay };
      }

      if (this.dataFilter === "lastMonth") {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        lastMonth.setHours(0, 0, 0, 0);

        const firstDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          1
        );
        const lastDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth() + 1,
          0
        );

        dateMatch = { start: firstDay, end: lastDay };
      }

      if (this.dataFilter === "thisWeek") {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const first = today.getDate() - today.getDay();
        const last = first + 6;

        const startThisWeek = new Date(today.setDate(first));
        startThisWeek.setHours(0, 0, 0, 0);
        const endThisWeek = new Date(today.setDate(last));
        endThisWeek.setHours(0, 0, 0, 0);

        endThisWeek.setDate(endThisWeek.getDate() - 1);

        dateMatch = { start: startThisWeek, end: endThisWeek };
      }

      if (this.dataFilter === "lastWeek") {
        const dayOfLastWeek = new Date();
        dayOfLastWeek.setHours(0, 0, 0, 0);
        dayOfLastWeek.setDate(dayOfLastWeek.getDate() - 7);

        const first = dayOfLastWeek.getDate() - dayOfLastWeek.getDay();
        const last = first + 6;

        const startLastWeek = new Date(dayOfLastWeek.setDate(first));
        startLastWeek.setHours(0, 0, 0, 0);
        const endLastWeek = new Date(dayOfLastWeek.setDate(last));
        endLastWeek.setHours(0, 0, 0, 0);

        dateMatch = { start: startLastWeek, end: endLastWeek };
      }

      if (this.dataFilter === "thisYear") {
        const currentYear = new Date().getFullYear();

        const firstDayOfYear = new Date(currentYear, 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        const lastDayOfYear = new Date(currentYear, 11, 31);
        lastDayOfYear.setHours(0, 0, 0, 0);

        dateMatch = { start: firstDayOfYear, end: lastDayOfYear };
      }

      if (this.dataFilter === "lastYear") {
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        const firstDayOfYear = new Date(lastYear.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        const lastDayOfYear = new Date(lastYear.getFullYear(), 11, 31);
        lastDayOfYear.setHours(0, 0, 0, 0);
        dateMatch = { start: firstDayOfYear, end: lastDayOfYear };
      }
      return dateMatch;
    },
    disabledDate() {
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      return (date) => date >= today;
    },
    hasDownloadBlocked() {
      return this.$store.state.acc.current_acc.blockDownloadExcel;
    },
  },
  methods: {
    handleConfirmDatePicker() {
      this.popupDatePicker = false;
      this.customDatePickerKey += 1;
      this.$nextTick(() => {
        this.timeOptionCustomText = !this.$refs.customDatePicker.text
          ? this.timeOptionCustomText
          : this.$refs.customDatePicker.text;
      });
      this.applyFilters(this.timeOptionCustomText);
    },
    async applyFilters(param) {
      if (param !== this.timeOptionCustomText) {
        this.datePickerValue = null;
        this.timeOptionCustomText = this.timeOptionCustomValue;
      }

      await this.getMarketingData(this.getDateByPeriod, this.chatOption.value);
      await this.getContactData(this.getDateByPeriod, this.chatOption.value);
      await this.getWebChatKpis(this.getDateByPeriod, this.chatOption.value);
      await this.getNpsCsatData(this.getDateByPeriod, this.chatOption.value);
      await this.getTmaTmrData(this.getDateByPeriod, this.chatOption.value);
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((res) => {
          if (this.chatOptions.length === 1) {
            this.activeChats = false;
            this.chatOption = this.chatOptions[0];
            for (let i = 0; i < res.data.data.length; i++) {
              this.chatOptions.push({
                label: res.data.data[i].name,
                value: res.data.data[i]._id,
              });
              if (res.data.data[i].status === 1) this.activeChats = true;
              if (res.data.data[i].status === 3) this.pendingChats = true;
            }
          }
        })
        .catch(() => {});
    },
    async getMarketingData(dateFilter, chatbot) {
      if (!chatbot) return;
      await this.$http
        .get("/p/chat/inbox/new-dashboard/marketing-2", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        })
        .then((res) => {
          this.marketingData = res.data.data;

          this.totalConversations =
            res.data.data.openedConversations.length > 0
              ? res.data.data.openedConversations
                  .map((el) => el.value)
                  .reduce((a, b) => a + b)
              : 0;

          if (
            this.marketingData.perIntention &&
            Object.keys(this.marketingData.perIntention).length > 0
          ) {
            this.marketingData.perIntention["Não Informado"] =
              this.totalConversations -
                Object.keys(this.marketingData.perIntention)
                  .map((key) => this.marketingData.perIntention[key])
                  .reduce((a, b) => a + b) || 0;
          }
          this.marketingKey += 1;
        })
        .catch(() => {});
    },
    async getContactData(dateFilter, chatbot) {
      if (!chatbot) return;
      await this.$http
        .get("/p/chat/inbox/new-dashboard/marketing-contact", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        })
        .then((res) => {
          this.contactData = res.data.data;
          this.totalContacts =
            res.data.data.newContacts.length > 0
              ? res.data.data.newContacts
                  .map((el) => el.value)
                  .reduce((a, b) => a + b)
              : 0;

          if (
            this.contactData.perIntention &&
            Object.keys(this.contactData.perIntention).length > 0
          ) {
            this.contactData.perIntention["Não Informado"] =
              this.totalContacts -
                Object.keys(this.contactData.perIntention)
                  .map((key) => this.contactData.perIntention[key])
                  .reduce((a, b) => a + b) || 0;
          }
          this.contactKey += 1;
        })
        .catch(() => {});
    },
    async getNpsCsatData(dateFilter, chatbot) {
      if (!chatbot) return;
      await this.$http
        .get("/p/chat/inbox/new-dashboard/nps-csat", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        })
        .then((res) => {
          this.NPSData = res.data.data.nps;
          this.CSATData = res.data.data.csat;
          this.keyCSAT += 1;
          this.keyNPS += 1;
        })
        .catch(() => {});
    },
    async getTmaTmrData(dateFilter, chatbot) {
      if (!chatbot) return;
      await this.$http
        .get("/p/chat/inbox/new-dashboard/tma-tmr", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        })
        .then((res) => {
          this.TMAData = res.data.data.tma;
          this.TMAAverage = res.data.data.tmaAverage;

          this.TMRData = res.data.data.tmr;
          this.TMRAverage = res.data.data.tmrAverage;

          this.keyTMA += 1;
          this.keyTMR += 1;
        })
        .catch(() => {});
    },
    async getWebChatKpis(dateFilter, chatbot) {
      if (!chatbot) return;
      await this.$http
        .get("/p/chat/inbox/new-dashboard/marketing-webchat", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        })
        .then((res) => {
          this.kpiDataReady = true;
          this.kpiData = res.data.data.kpis;
          this.marketingKey += 1;
        })
        .catch(() => {
          this.kpiDataReady = true;
        });
    },
    async downloadCSV() {
      window.analytics.track(
        "Download Marketing",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$vs.loading();
      try {
        await this.$http.get("/p/chat/inbox/conversationsCsv", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: this.getDateByPeriod,
            chatbot:
              this.chatOption.value !== "all" ? this.chatOption.value : null,
          },
        });
        this.$vs.dialog({
          color: "primary",
          title: this.$t("Success"),
          text: this.$t("InboxConversationsXLSXIsGenerating"),
          acceptText: this.$t("Close"),
        });
        this.hasDownloadBlockedLocal = true;
        this.downloadBlockedMessage = this.$t(
          "InboxConversationsXlsxGenerationBlocked2"
        );
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.notify({
          title: this.$t("Error"),
          text:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        this.$vs.loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#chatbotFilter {
  margin-right: 10px;
}

.mx-datepicker-main {
  z-index: 40000;
}

:deep(.mx-input) {
  width: 100%;
  height: 39px;
  padding: 10px;
  font-size: 1rem;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.mx-datepicker) {
  width: 100%;
  height: 39px;
}
</style>
